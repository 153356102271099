<template>
	<v-data-table
		item-key="itemId"
		fixed-header
		disable-sort
		show-expand
		single-expand
		no-data-text=""
		no-results-text=""
		hide-default-footer
		:headers="tableHeaders"
		:items="catalogItemList"
		:height="tableHeight"
		:loading="loading"
		:server-items-length="serverItemsLength"
		:items-per-page="itemsPerPage"
		:expanded.sync="expandedItems"
		v-on:item-expanded="loadDetails"
		v-on:click:row="onClickTableRow"
	>
		<template v-slot:[`item.imageThumbUrl`]="{ item }">
			<v-img
				class="my-2"
				:src="item.imageThumbUrl"
				:width="50"
				:height="50"
				position="center center"
				contain
			/>
		</template>
		<template v-slot:[`item.webUserPrice`]="{ item }">
			{{ item.webUserPrice | toCurrency }}
		</template>
		<template v-slot:[`item.catalogPrice`]="{ item }">
			{{ item.catalogPrice | toCurrency }}
		</template>

		<!-- CATALOGITEM DETAIL -->
		<template v-slot:expanded-item="{ headers, item }">
			<td :colspan="headers.length">
				<v-container>
					<v-row align="center" justify="center">
						<v-col cols="12" md="2" align="center">
							<v-img
								:src="item.imageUrl"
								:width="250"
								:height="250"
								position="left center"
								contain
							/>
						</v-col>
						<v-col cols="12" md="9">
							<v-row v-if="item.itemDescription">
								<v-col>
									<span class="blockquote">
										{{ item.itemDescription }}</span
									>
								</v-col>
							</v-row>
							<v-row>
								<v-col>
									<v-data-table
										dense
										item-key="productCode"
										disable-sort
										hide-default-footer
										:items="item.itemSizes"
										:headers="detailHeaders"
									>
										<template
											v-slot:[`item.actions`]="{ item }"
										>
											<CartItemToolbar
												v-if="!readOnly"
												:product-code="item.productCode"
											/>
										</template>
									</v-data-table>
								</v-col>
							</v-row>
						</v-col>
					</v-row>
				</v-container>
			</td>
		</template>

		<!-- EXTERNAL PAGINATION -->
		<template v-slot:footer>
			<v-divider />
			<v-pagination
				v-if="pageCount > 0"
				class="pt-3"
				light
				v-model="currentPage"
				:length="pageCount"
				total-visible="7"
			></v-pagination>
		</template>
	</v-data-table>
</template>

<script>
	import { mapGetters } from "vuex";
	import CartItemToolbar from "../components/CartItemToolbar.vue";

	export default {
		name: "CatalogItemTable",
		components: { CartItemToolbar },
		props: { tableHeight: null },
		data() {
			return {
				expandedItems: [],
				loading: false,
				detailHeaders: [
					{ text: "itemSize", value: "itemSize" },
					{ text: "productCode", value: "productCode" },
					{ text: "catalogPrice", value: "catalogPrice" },
					{ text: "promoPrice", value: "promoPrice" },
					{ text: "minimalOrderQty", value: "minimalOrderQty" },
					{ text: "", value: "actions" },
				],
			};
		},
		computed: {
			...mapGetters({
				serverItemsLength: "products/serverItemsLength",
				catalogItemList: "products/catalogItemList",
				pageCount: "products/pageCount",
				itemsPerPage: "products/itemsPerPage",
				readOnly: "session/readOnly",
				webUserInfo: "session/webUserInfo",
			}),
			currentPage: {
				get() {
					return this.$store.state.products.ui.currentPage;
				},
				set(page) {
					this.loading = true;
					this.$store
						.dispatch("products/setCurrentPage", page)
						.then(() => {
							this.$store
								.dispatch("products/fetchCatalogItemList")
								.then(() => {
									this.loading = false;
								});
						});
				},
			},
			tableHeaderClass() {
				return "white";
			},
			tableHeaders() {
				var x = [
					{
						text: "imageThumbUrl",
						value: "imageThumbUrl",
						class: this.tableHeaderClass,
					},
					{
						text: "itemId",
						value: "itemId",
						class: this.tableHeaderClass,
					},
					{
						text: "itemName",
						value: "itemName",
						class: this.tableHeaderClass,
					},
					{
						text: "brandName",
						value: "brandName",
						class: this.tableHeaderClass,
					},
				];
				var w = {
					text: "",
					value: "data-table-expand",
					class: this.tableHeaderClass,
				};
				var y = {
					text: "catalogPrice",
					value: "catalogPrice",
					align: "right",
					class: this.tableHeaderClass,
				};
				var z = {
					text: "webUserPrice",
					value: "webUserPrice",
					align: "right",
					class: this.tableHeaderClass,
				};

				if (this.webUserInfo[0].hasPrivateCatalog) {
					x.push(z);
				} else {
					x.push(y);
				}
				x.push(w);
				return x;
			},
		},
		methods: {
			loadDetails({ item }) {
				if (item.itemSizes) return;
				this.$store
					.dispatch("products/fetchCatalogItemDetail", item)
					.then(() => {
						this.expandedItems = [];
						this.expandedItems.push(item);
					});
			},
			onClickTableRow(first, second) {
				second.expand();
			},
		},
	};
</script>

