<template>
	<div>
		<v-toolbar v-if="!isDirty" dense flat>
			<v-spacer />
			<v-btn plain @click="startUpsert"
				><v-icon>mdi-basket-outline</v-icon>
				<v-badge v-show="qtyInCart" :content="qtyInCart" right />
			</v-btn>
			<v-spacer />
		</v-toolbar>
		<v-toolbar v-else dense flat>
			<v-row align="center">
				<v-spacer />
				<v-col align="right">
					<v-btn
						v-show="isDirty"
						icon
						color="red"
						@click.stop="cancelUpsert"
					>
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-col>
				<v-col>
					<v-text-field
						v-model="quantity"
						type="number"
						outlined
						dense
						hide-details
						@keyup.native.enter="upsert"
						@input="isDirty = true"
					/>
				</v-col>
				<v-col>
					<v-btn
						v-show="isDirty"
						icon
						color="green"
						:disabled="this.quantity <= 0"
						@click.stop="upsert()"
					>
						<v-icon medium> mdi-check </v-icon>
					</v-btn>
				</v-col>
				<v-spacer />
			</v-row>
		</v-toolbar>
	</div>
</template>
<script>
	export default {
		name: "CartItemToolbar",
		props: {
			productCode: String,
		},
		data() {
			return {
				upserting: false,
				isDirty: false,
				quantity: 1,
				cartItemId: "",
				qtyInCart: 0,
			};
		},
		mounted() {
			this.findProductInCart();
		},
		methods: {
			startUpsert() {
				this.upserting = true;
				this.isDirty = true;
				if (this.cartItemId) {
					this.quantity = this.qtyInCart;
				} else {
					this.quantity = 1;
				}
			},
			cancelUpsert() {
				if (this.cartItemId) {
					// cancel 'update'
					this.quantity = this.qtyInCart;
				} else {
					// cancel 'add'
					this.upserting = false;
					this.quantity = null;
				}
				this.isDirty = false;
			},
			upsert() {
				let cartItem = {
					cartItemId: this.cartItemId,
					productCode: this.productCode,
					quantity: Number(this.quantity),
				};
				this.$store
					.dispatch("shoppingCart/upsertCartItem", cartItem)
					.then(() => {
						this.isDirty = false;
						this.upserting = false;
						this.findProductInCart();
					})
					.catch((error) => {
						alert(error);
					});
			},
			findProductInCart() {
				this.$store
					.dispatch("shoppingCart/findProduct", this.productCode)
					.then((response) => {
						this.cartItemId = response.cartItemId;
						this.qtyInCart = response.quantity;
					});
			},
		},
	};
</script>
